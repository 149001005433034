(function () {

    d3.bullet = function (width, height, fromClickedView, isHoverChiller = false) {
        var orient = "left", // TODO top & bottom
            reverse = false,
            duration = 0,
            ranges = bulletRanges,
            markers = bulletMarkers,
            width = width,
            height = height,
            line1 = bulletline1,
            line2 = bulletline2,
            joinLine = bulletJoinLine,
            polygon = bulletPolygon,
            tickFormat = d3.format(",");

        function bullet(g) {
            g.each(function (d, i) {
                var rangez = ranges.call(this, d, i).slice().sort(d3.descending),
                    markerz = markers.call(this, d, i),
                    line1z = line1.call(this, d, i).slice().sort(d3.descending),
                    line2z = line2.call(this, d, i).slice().sort(d3.descending),
                    joinLinez = joinLine.call(this, d, i).slice().sort(d3.descending),
                    polygonz = polygon.call(this, d, i).slice().sort(d3.descending),
                    g = d3.select(this);
                tickMinVal = d.minValue;
                tickMaxVal = d.maxValue;


                // Compute the new x-scale.
                var x1 = d3.scaleLinear()
                    .domain([0, Math.max(rangez[0])])
                    .range(reverse ? [width, 0] : [0, width]);

                // Retrieve the old x-scale, if this is an update.
                var x0 = this.__chart__ || d3.scaleLinear()
                    .domain([0, Infinity])
                    .range(x1.range());

                // Stash the new scale.
                this.__chart__ = x1;

                // Derive width-scales from the x-scales.
                var w0 = bulletWidth(x0),
                    w1 = bulletWidth(x1);

                // Update the range rects.
                var range = g.selectAll("rect.range")
                    .data(rangez);

                range.enter().append("rect")
                    .attr("class", function (d, i) { return "range s" + i; })
                    .attr("width", w0)
                    .attr("height", height)
                    .attr("x", reverse ? x0 : 0)
                    .transition()
                    .duration(duration)
                    .attr("width", w1)
                    .attr("rx", function (d, i) { return calculateChartBorderConditionally(fromClickedView, i, isHoverChiller); })
                    .attr("ry", function (d, i) { return calculateChartBorderConditionally(fromClickedView, i, isHoverChiller); })
                    .attr("x", reverse ? x1 : 0);

                range.transition()
                    .duration(duration)
                    .attr("x", reverse ? x1 : 0)
                    .attr("width", w1)
                    .attr("height", height);

                var fillGreyArea = g.selectAll("polygon.deltaArea")
                    .data(polygonz);
                
                fillGreyArea.enter().append("polygon")
                    .attr("class", "deltaArea")
                    .attr("points", function (d, i) {
                        return x1(d.x1) +`${fromClickedView ? responsiveDeltaStyling(" 15"): isHoverChiller? " 45":" 65"}`+","+ x1(d.x2) +`${fromClickedView ? responsiveDeltaStyling(" 15"):  isHoverChiller? " 45":" 65"},`+ x1(d.x2) +`${fromClickedView ? responsiveDeltaStyling(" 31"): isHoverChiller? " 95":" 135" },`+ x1(d.x1) +`${fromClickedView ? responsiveDeltaStyling(" 31"):isHoverChiller? " 95":" 135" }`;
                    })
                    .attr("fill", "#666")
                    .attr("stroke","#999");

                // Update the marker lines.
                var marker = g.selectAll("line.marker")
                    .data(markerz);

                marker.enter().append("line")
                    .attr("class", "marker")
                    .attr("x1", x0)
                    .attr("x2", x0)
                    .attr("y1", -5)
                    .attr("y2", height + 5)
                    .transition()
                    .duration(duration)
                    .attr("x1", x1)
                    .attr("x2", x1);

                let outerMarkerLine = marker.enter().append("line")
                    .attr("class", "line1")
                    .attr("x1", x0)
                    .attr("x2", x0)
                    .attr("y1", (fromClickedView ? -5: -4))
                    .attr("y2", height + (fromClickedView ? 5: 4))
                    .transition()
                    .duration(duration)
                    .attr("x1", x1)
                    .attr("x2", x1);

                marker.transition()
                    .duration(duration)
                    .attr("x1", x1)
                    .attr("x2", x1)
                    .attr("y1", height / 6)
                    .attr("y2", height * 5 / 6);

                // Compute the tick format.
                var format = tickFormat || x1.tickFormat(5);

                // Update the tick groups.
                var tick = g.selectAll("g.tick")
                    .data(x1.ticks(5), function (d) {
                        return this.textContent + " F" || format(d);
                    });

                // Initialize the ticks with the old scale, x0.
                var tickEnter = tick.enter().append("g")
                    .attr("class", "tick")
                    .attr("transform", bulletTranslate(x0))
                    .style("opacity", 1e-6);

                tickEnter.append("line")
                    .attr("y1", height)
                    .attr("y2", height * 7 / 5);

                tickEnter.append("text")
                    .attr("class", function (d, i, array) { return (i == 0 || i == 4) ? "bulletTickText" : "displayNone" })
                    .attr("text-anchor", "middle")
                    .attr("dy", "1em")
                    .attr("y", `${responsiveBulletTextStyling(fromClickedView)}`)
                    .attr("transform", function (d, i, array) {
                        if (i == 0) {
                            return `${fromClickedView ? "translate(10,-30)" : isHoverChiller ? "translate(30,-20)" : "translate(40,0)"}`;
                        } else if (i == 1) {
                            return "translate(0,0)";
                        } else if (i == 2) {
                            return "translate(0,0)";
                        } else if (i == 3) {
                            return "translate(0,0)";
                        } else if (i == 4) {
                            return `${fromClickedView ? "translate(-10,-30)" : isHoverChiller ? "translate(-35,-20)" : "translate(-40,0)"}`;
                        }
                    })
                    .text(function (g, i, array) { return (i == 0) ? tickMinVal : tickMaxVal });

                // Transition the entering ticks to the new scale, x1.
                tickEnter.transition()
                    .duration(duration)
                    .attr("transform", function (d, i, array) {
                        if (i == 0) {
                            return `${fromClickedView ? "translate(1,0)" : isHoverChiller ? "translate(0,0)" : "translate(0,0)"}`;
                        } else if (i == 1) {
                            return `${fromClickedView ? updateTicksBasedOnWidth(width, 1): isHoverChiller ? "translate(140,0)" : "translate(195,0)"}`;
                        } else if (i == 2) {
                            return `${fromClickedView ? updateTicksBasedOnWidth(width, 2): isHoverChiller ? "translate(280,0)" : "translate(390,0)"}`;
                        } else if (i == 3) {
                            return `${fromClickedView ? updateTicksBasedOnWidth(width, 3): isHoverChiller ? "translate(420,0)" : "translate(585,0)"}`;
                        } else if (i == 4) {
                            return `${fromClickedView ? updateTicksBasedOnWidth(width, 4): isHoverChiller ? "translate(545,0)" : "translate(780,0)"}`;
                        }
                    })
                    .style("opacity", 1);

                // Transition the updating ticks to the new scale, x1.
                var tickUpdate = tick.transition()
                    .duration(duration)
                    .attr("transform", bulletTranslate(x1))
                    .style("opacity", 1);

                tickUpdate.select("line")
                    .attr("y1", height)
                    .attr("y2", height * 7 / 6);

                tickUpdate.select("text")
                    .attr("y", height * 7 / 6);


                // Transition the exiting ticks to the new scale, x1.
                tick.exit().transition()
                    .duration(duration)
                    .attr("transform", bulletTranslate(x1))
                    .style("opacity", 1e-6)
                    .remove();

                // 	
                var line = g.selectAll("line.secondaryLine1")
                    .data(line1z);
                line.enter().append("line")
                    .attr("class", "secondaryLine1")
                    .attr("id", "firstLine1")
                    .attr("y1", `${fromClickedView ? responsiveDeltaStyling(15, true) : isHoverChiller ? 45 : 65}`)
                    .attr("y2", `${fromClickedView ? responsiveDeltaStyling(31, true) : isHoverChiller ? 115 : 135}`)
                    .transition()
                    .duration(duration)
                    .attr("x1", x1)
                    .attr("x2", x1);

                var secondLine = g.selectAll("line.secondaryLine2")
                    .data(line2z);
                secondLine.enter().append("line")
                    .attr("class", "secondaryLine2")
                    .attr("id", "firstLine2")
                    .attr("y1", `${fromClickedView ? responsiveDeltaStyling(15, true) : isHoverChiller ? 45 : 65}`)
                    .attr("y2", `${fromClickedView ? responsiveDeltaStyling(31, true) : isHoverChiller ? 115 : 135}`)
                    .transition()
                    .duration(duration)
                    .attr("x1", x1)
                    .attr("x2", x1);

                var joinsecondaryLine = g.selectAll("line.secondaryLine3")
                    .data(joinLinez);

                joinsecondaryLine.enter().append("line")
                    .attr("class", "secondaryLine3")
                    .attr("x1", function (d, i) {
                        return x1(d.x1);
                    })
                    .attr("x2", function (d, i) {
                        return x1(d.x2);
                    })
                    .attr("y1", `${fromClickedView ? responsiveDeltaStyling(31, true): isHoverChiller ? 95 : 135}`)
                    .attr("y2", `${fromClickedView ? responsiveDeltaStyling(31, true): isHoverChiller ? 95 : 135}`)
                    .transition()
                    .duration(duration);


            });
        }

        // left, right, top, bottom
        bullet.orient = function (x) {
            if (!arguments.length) return orient;
            orient = x;
            reverse = orient == "right" || orient == "bottom";
            return bullet;
        };

        // ranges (bad, satisfactory, good)
        bullet.ranges = function (x) {
            if (!arguments.length) return ranges;
            ranges = x;
            return bullet;
        };

        // markers (previous, goal)
        bullet.markers = function (x) {
            if (!arguments.length) return markers;
            markers = x;
            return bullet;
        };

        bullet.line1 = function (x) {
            if (!arguments.length) return line1;
            line1 = x;
            return bullet;
        };

        bullet.line2 = function (x) {
            if (!arguments.length) return line2;
            line2 = x;
            return bullet;
        };

        bullet.width = function (x) {
            if (!arguments.length) return width;
            width = x;
            return bullet;
        };

        bullet.height = function (x) {
            if (!arguments.length) return height;
            height = x;
            return bullet;
        };

        bullet.tickFormat = function (x) {
            if (!arguments.length) return tickFormat;
            tickFormat = x;
            return bullet;
        };

        bullet.duration = function (x) {
            if (!arguments.length) return duration;
            duration = x;
            return bullet;
        };
        return bullet;
    };

    function calculateChartBorderConditionally(clickedView, i,isHoverChiller) {
        if (clickedView) {
            return 3;
        }else if(isHoverChiller){
            return 5;
        }
        return i == 2 ? 5 : 10;
    }

    function bulletRanges(d) {
        return d.ranges;
    }

    function bulletMarkers(d) {
        return d.markers;
    }

    function bulletTranslate(x) {
        return function (d) {
            return "translate(" + x(d) + ",0)";
        };
    }

    function bulletWidth(x) {
        var x0 = x(0);
        return function (d) {
            return Math.abs(x(d) - x0);
        };
    }

    function bulletline1(d) {
        return d.line1;
    }

    function bulletline2(d) {
        return d.line2;
    }

    function bulletJoinLine(d) {
        return d.joinLine;
    }

    function bulletPolygon(d) {
        return d.polygon;
    }

    function responsiveBulletTextStyling(fromClickView) {
        let textSize = fromClickView ? 55: 80;
        if (fromClickView) {
            if (window.innerWidth >= 1800) {
                if (window.innerHeight >= 900) {
                    textSize = 65;
                }
            }
        }
        return textSize
    }

    function responsiveDeltaStyling(data, fromLine = false) {
        let deltaPos = data;
        if (deltaPos == " 15" || fromLine) {
            if (window.innerWidth >= 1800) {
                if (window.innerHeight >= 900) {
                    if (fromLine) {
                        deltaPos = 25; 
                    } else {
                        deltaPos = " 25";
                    }
                }
            }
        } else if (deltaPos == " 31" || fromLine) {
            if (window.innerWidth >= 1800) {
                if (window.innerHeight >= 900) {
                    if (fromLine) {
                        deltaPos = 36;
                    } else {
                        deltaPos = " 36";
                    }
                }
            }
        }
        return deltaPos
    }

    function updateTicksBasedOnWidth(width, index) {
        const data = width/5;
        return `translate(${data*(index+1)},0)`;
    }

})();









